import { Controller } from '@hotwired/stimulus'

/**
 * This stimulus controller is intended to be used with invalid form inputs, to remove the
 * invalid state indicators when the user changes the input value.
 *
 * It assumes a single element (in an error state, marked with `form-group--error`)
 * Only a single type of input is expected within the form group (input, select, OR textarea element);
 * However, there may be hidden inputs also present (such as when a tom select is generated alongside a regular select)
 */
export default class InvalidFieldController extends Controller {

  assumeFieldIsValid() {
    this.removeFieldErrorState()
    this.hideErrorMessage()
  }

  removeFieldErrorState() {
    const errorFormGroup = this.errorFormGroup()
    if (errorFormGroup) {
      const inputSelector = `input, select, textarea`
      errorFormGroup.querySelectorAll(inputSelector).forEach(e => e.setAttribute('aria-invalid', 'false'))
      errorFormGroup.classList.remove('form-group--error')
    }
  }

  errorFormGroup() {
    return this.element.classList.contains('form-group--error') ?
      this.element :
      this.element.querySelector('.form-group--error')
  }

  hideErrorMessage() {
    if (this.errorMessage() && !this.errorMessage().hasAttribute('hidden')) {
      this.errorMessage().setAttribute('hidden', 'true')
    }
  }

  errorMessage() {
    return this.element.querySelector('.form-error')
  }
}
